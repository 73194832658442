import 'styles/eastRidingHome.scss'

// import "../base/home.js"
// import {Urls} from '../constants'
// import moment from 'moment';
// import { loader } from "../utils/loader.js";
// import { displayDateTime } from "../utils/displayDateTime.js";


(function () {
    /* global $ */
    "use strict";   

    let currentState = {
        attributes: '',
        page: 1,
        pageCount: 1,
        ItemsPerPage: '12',
        SortColumn: 'TransactionDate',
        SortType: 'asc',
        search: ''
    }

    async function getPaListEndpoint() {
        const endpoint = new URL(`${window.location.protocol}//${window.location.host}/s4s/api/Event/GetEventsJson`);
        
        endpoint.searchParams.set('attrValue', currentState.attributes);
        endpoint.searchParams.set('templateId', siteConstants.paTemplate);
        endpoint.searchParams.set('orgId', siteConstants.orgId);
        endpoint.searchParams.set('search', currentState.search);
        endpoint.searchParams.set('page', currentState.page);
        endpoint.searchParams.set('itemsPerPage', currentState.ItemsPerPage);
        endpoint.searchParams.set('fields', 'Id,Name,ListImagePath,Description,Address,Postcode,Phone,Email,Website,Attributes');
        endpoint.searchParams.set('isFullPhraseMatch', 'true');
        endpoint.searchParams.set('SortType', currentState.SortType);

        const response = await fetch(endpoint);
        const data = await response.json();
        // console.log(data)
        return data;
    }

    async function getVacanciesListEndpoint() {
		const endpoint = new URL(`${window.location.protocol}//${window.location.host}/s4s/api/Event/GetEventsJson`);
        
        endpoint.searchParams.set('attrValue', currentState.attributes);
        endpoint.searchParams.set('templateId', siteConstants.vacanciesTemplate);
		endpoint.searchParams.set('orgId', siteConstants.orgId);
        endpoint.searchParams.set('search', currentState.search);
        endpoint.searchParams.set('page', currentState.page);
        endpoint.searchParams.set('itemsPerPage', currentState.ItemsPerPage);
        endpoint.searchParams.set('fields', 'Id,Name,ListImagePath,Description,Address,Postcode,Phone,Email,Website,Attributes');
        endpoint.searchParams.set('isFullPhraseMatch', 'true');
        endpoint.searchParams.set('SortType', currentState.SortType);

		const response = await fetch(endpoint);
		const data = await response.json();
        // console.log(data)
		return data;
	}

    async function createMyVacanciesPanel() {
        const getVacancies = await getVacanciesListEndpoint();

        if(getVacancies.Paging.TotalItemsCount === 1){
            $('.js-available-vacancies h3').text(`${getVacancies.Paging.TotalItemsCount}`)
            $('.js-available-vacancies p').text('Available job')
         
        } else if(getVacancies.Paging.TotalItemsCount === -1){
            $('.js-available-vacancies h3').text('0')
            $('.js-available-vacancies p').text('Available jobs')
          
        }else{
            $('.js-available-vacancies h3').text(`${getVacancies.Paging.TotalItemsCount}`)
            $('.js-available-vacancies p').text('Available jobs')
           
        }
    }  


    async function createMyPaPanel() {
        const getPAs = await getPaListEndpoint();
        const getVacancies = await getVacanciesListEndpoint();

        if(getPAs.Paging.TotalItemsCount === 1){
            $('.js-available-pa .h3').text(`${getPAs.Paging.TotalItemsCount}`)
            $('.js-available-pa p').text('Available PA in your area')
          
        } else if(getPAs.Paging.TotalItemsCount === -1){
            $('.js-available-pa .h3').text('0')
            $('.js-available-pa p').text('Available PAs in your area')
           
        }else{
            $('.js-available-pa .h3').text(`${getPAs.Paging.TotalItemsCount}`)
            $('.js-available-pa p').text('Available PAs in your area')
           
        }


        if(getVacancies.Paging.TotalItemsCount === 1){
            $('.js-available-vacancies .h3').text(`${getVacancies.Paging.TotalItemsCount}`)
            $('.js-available-vacancies p').text('Available vacancy in your area')
          
        } else if(getVacancies.Paging.TotalItemsCount === -1){
            $('.js-available-vacancies .h3').text('0')
            $('.js-available-vacancies p').text('Available vacancies in your area')
           
        }else{
            $('.js-available-vacancies .h3').text(`${getVacancies.Paging.TotalItemsCount}`)
            $('.js-available-vacancies p').text('Available vacancies in your area')
           
        }

    }    

    
    function createPage() {
        createDashboardBody();
        createMyPaPanel()
        createMyVacanciesPanel()
    }

    function createDashboardBody(){

        $('.js-home').append(
            `
            <h1 style="text-indent: -10000px; height: 0;">Welcome to East Riding PA Web!</h1>
            <section class="carousel-container" aria-label="East Riding PA Carousel">
                <div class="carousel">
                    <div id="slide-1" class="slide" style="display:''">
                        <div class="slide-img">
                            <img src="/resources/Councils/EastRiding/img/carousel-1.jpg" alt="Carousel Image 1">
                        </div>
                        <div class="slide-text slide-text-first">
                            <h2>Welcome to East Riding PA Web!</h2>
                            <p>Find information and advice about working as or employing a Personal Assistant in Care. 
                            You can also browse PA Profiles, local vacancies and send messages.</p>
                        </div>
                    </div>
                    <div id="slide-2" class="slide" style="display:none">
                        <div class="slide-img">
                            <img src="/resources/Councils/EastRiding/img/pa.jpg" alt="Carousel Image 2">
                        </div>
                        <div class="slide-text slide-text-second">
                            <h2>Welcome to East Riding PA Web!</h2>
                            <p>Considering becoming a Personal Assistant in Care? Register for a profile on the website or find out more.</p>
                        </div>
                    </div>
                   
                    <button class="prev-btn" aria-label="Previous Slide"><i class="fas fa-angle-left"></i></button>
                    <div class="carousel-nav">
                        <button class="js-pause-carousel" aria-label="Pause carousel"><i class="fas fa-pause"></i></button>
                        <div class="dots">
                            <button class="dot activeSlide"></button>
                            <button class="dot"></button>
                        </div>
                    </div>
                    <button class="next-btn" aria-label="Next Slide"><i class="fas fa-angle-right"></i></button>
                </div>
            </section>

            <section class="looking-for-container-er" aria-label="What do you need help with">
                <h2>I am looking for?</h2>
                <div class="looking-for-content">
                    <article aria-labelledby="article-1" class="looking-for-tile">
                        <div>
                            <img src="/resources/Councils/EastRiding/img/find-a-pa.jpg" alt="I am looking for a PA">
                            <h3 id="article-1">Find a PA or micro-provider</h3>
                        </div>
                        <div>
                            <a class="plain-btn-er" href="/s4s/CustomPage/Index/${Urls.pa_search_er}" aria-describedby="article-1">Browse our bank of PAs and micro-providers</a>
                        </div>                    
                    </article>
                    <article aria-labelledby="article-2" class="looking-for-tile">
                        <div>
                            <img src="/resources/Councils/EastRiding/img/employer.jpg" alt="I am a PA">
                            <h3 id="article-2" >Information about being an employer</h3>
                        </div>
                        <div>
                            <a class="plain-btn-er" href="/s4s/CustomPage/Index/${Urls.what_is_employer_er}" aria-describedby="article-2">Learn about employing a PA</a>
                        </div>
                    </article>
                    <article aria-labelledby="article-3" class="looking-for-tile">
                        <div>
                            <img src="/resources/Councils/EastRiding/img/find-a-vacancy.jpg" alt="I'd just like some information">
                            <h3 id="article-3" >Find a vacancy</h3>
                        </div>
                        <div>
                            <a class="plain-btn-er" href="/s4s/CustomPage/Index/${Urls.vacancies_search_er}" aria-describedby="article-3">Browse our vacancies</a>
                        </div>
                    </article>
                    <article aria-labelledby="article-4" class="looking-for-tile">
                        <div>
                            <img src="/resources/Councils/EastRiding/img/pa.jpg" alt="Information about being a PA">
                            <h3 id="article-4" >Information about being a PA</h3>
                        </div>
                        <div>
                            <a class="plain-btn-er" href="/s4s/CustomPage/Index/${Urls.what_is_pa_er}" aria-describedby="article-4">Learn about working as a PA</a>
                        </div>
                    </article>
                </div>
                <div class="looking-for-logos">
                    <article id="article-6" class="looking-for-logos-tile">
                        <a href="https://www.yourlifeyourway.uk/" target="blank"><img src="/resources/Councils/EastRiding/img/YLYW.png" alt="Your Life, Your Way, East Riding"></a>                        
                    </article>
                    <article id="article-7" class="looking-for-logos-tile">
                        <a href="https://www.choosecareeastriding.co.uk/" target="blank"><img src="/resources/Councils/EastRiding/img/choose-care.png" alt="Choose Care Change Lives"></a>
                    </article>
                    <article id="article-8" class="looking-for-logos-tile">
                        <a href="https://www.eastriding.gov.uk/" target="blank"><img src="/resources/Councils/EastRiding/img/east-riding.png" alt="East Riding Council"></a>
                    </article>
                    <article id="article-9" class="looking-for-logos-tile">
                        <a href="https://www.eastridinglocaloffer.org.uk/" target="blank"><img src="/resources/Councils/EastRiding/img/local-offer.png" alt="Local Offer"></a>
                    </article>
                </div>
            </section>

            <section class="available-container-er" aria-label="Available PAs">
                <h2>Right now there are...</h2>
              
                <div class="available-content">
                    <div class="available-tile">
                        <div class="available-tile-text js-available-pa">
                            <span class="h3"></span>
                            <p></p>
                            <a class="plain-btn-er"  href="/s4s/CustomPage/Index/${Urls.pa_search_er}">Search for PA</a>
                        </div>
                        <div class="available-tile-image">
                            <img src="/resources/Councils/EastRiding/img/available-pa.jpg" alt="Available PA">
                        </div>
                    </div>
                    <div class="available-tile">
                        <div class="available-tile-text js-available-vacancies">
                            <span class="h3"></span>
                            <p>Available vacancies in your area</p>
                            <a class="plain-btn-er"  href="/s4s/CustomPage/Index/${Urls.vacancies_search_er}">Search vacancies</a>
                        </div>
                        <div class="available-tile-image">
                            <img src="/resources/Councils/EastRiding/img/available-vacancies.jpg" alt="Available Vacancies">
                        </div>
                    </div>
                </div>
               
            </section>

            <section class="news-container-er" aria-label="News">
                <h2>Hear what people are saying...</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean rutrum nisi e</p>
                <div class="available-content">
                    <div class="available-tile">
                        <div class="available-tile-image">
                            <img src="/resources/Councils/EastRiding/img/find-a-pa.jpg" alt="News 1">
                        </div>
                        <div class="available-tile-text">
                            <h3>Lorem ipsum dolor</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean rutrum nisi e</p>
                            <a class="plain-btn-er"  href="/">Lorem ipsum dolor</a>
                        </div>
                    </div>
                    <div class="available-tile">
                        <div class="available-tile-image">
                            <img src="/resources/Councils/EastRiding/img/employer.jpg" alt="News 2">
                        </div>
                        <div class="available-tile-text">
                            <h3>Lorem ipsum dolor</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean rutrum nisi e</p>
                            <a class="plain-btn-er"  href="/">Lorem ipsum dolor</a>
                        </div>
                    </div>
                </div>
               
            </section>

            `
        )
    }

    function checkAuth() {   
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('page');
    
        if (myParam === 'home') {
          createPage()
        } else {
          // Check if the user is employee
            if ($.sc.authSettings.isEmployee === true) {
                window.location = "/s4s/CustomPage/Index/" + Urls.dashboard_er;
            } else {
                createPage()
            } 
        }
    
    }
   

    var Attributes = {
        init: function () {
            this.adjustDom();
            this.bindUIActions();
            this.unload();
        },

        adjustDom: function () {

            checkAuth()        
            const slides = document.querySelectorAll('.slide');
            const dots = document.querySelectorAll('.dot');
            const prevBtn = document.querySelector('.prev-btn');
            const nextBtn = document.querySelector('.next-btn');
            const pauseBtn = document.querySelector('.js-pause-carousel');

            let currentIndex = 0;
            let autoplayInterval;

            function showSlide(index) {
                slides.forEach((slide, i) => {
                    slide.style.display = i === index ? '' : 'none';
                });

                dots.forEach((dot, i) => {
                    dot.classList.toggle('activeSlide', i === index);
                });

            }

            function prevSlide() {
                currentIndex = (currentIndex - 1 + slides.length) % slides.length;
                showSlide(currentIndex);
            }

            function nextSlide() {
                currentIndex = (currentIndex + 1) % slides.length;
                showSlide(currentIndex);
            }

            function startAutoplay() {
                autoplayInterval = setInterval(nextSlide, 4000); // Change slide every 4 seconds
            }

            function stopAutoplay() {
                clearInterval(autoplayInterval);
            }

            function createPlayBtn() {
                pauseBtn.innerHTML = '<i class="fas fa-play"></i>'
            }

            function createPauseBtn() {
                pauseBtn.innerHTML = '<i class="fas fa-pause"></i>'
            }

            dots.forEach((dot, i) => {
            dot.addEventListener('click', () => {
                stopAutoplay();
                showSlide(i);
            });
            });

            prevBtn.addEventListener('click', () => {
                stopAutoplay();
                prevSlide();
            });

            nextBtn.addEventListener('click', () => {
                stopAutoplay();
                nextSlide();
            });

        
            $('body').on('click', '.js-pause-carousel', function(){
                if ( $(this).hasClass('selected')) {
                    startAutoplay();
                    createPauseBtn();
                    $(this).attr('aria-label', 'Pause carousel');
                    $(this).removeClass('selected');

                } else {
                    stopAutoplay();
                    createPlayBtn();
                    $(this).attr('aria-label', 'Play carousel');
                    $(this).addClass('selected')
                }
            });

            startAutoplay();
            // const languageStored = localStorage.getItem('language');
        },

        bindUIActions: function () {
            // translationClick()const jsEngBtn = document.querySelector('.js-eng-btn');
        
        },

        unload: function () {},
    };

    Attributes.init();
})();